<template>
    <div :style="{ 'flex': 1, 'min-height': 0 }">
        <InfiniteCardList
            :data="formattedHotels"
            :config="eventPackageCardConfig"
            :loading="loading"
            :has-load-button="false"
            :prefill="true"
            item-classes="gst-event-package-offers-list__list-item px-0"
            list-classes="pt-0"
            class="gst-event-package-offers-list u-height-100 u-width-100 px-2"
            :bus-events-parent="busEventParent"
            @click-item="$emit('select', $event._originalData)"
            @more-info="$emit('more-info', $event)"
            @load-more="$emit('load-more')">
            <template slot="loading">
                <DataLoading v-if="loading" class="my-5 py-5" />
            </template>
            <template slot="empty-list">
                <EventPackageOfferHotelsEmptyState />
            </template>
        </InfiniteCardList>
    </div>
</template>

<script>
    import { mapGetters, mapState } from 'vuex';
    import offerConstants from '@core/utils/constants/offer';
    import DataLoading from '@core/shared/components/loading/DataLoading';
    import InfiniteCardList from '@core/shared/components/lists/InfiniteCardList';
    import { getDistanceUnitOfMeasureForCountry } from '@core/utils/measureUtils';
    import RoomCardVariant4 from '@tenant/app/components/cards/RoomCardVariant4';
    import EventPackageOfferHotelsEmptyState from './EventPackageOfferHotelsEmptyState';

    export default {
        name: 'EventPackageOffersList',
        components: {
            DataLoading,
            InfiniteCardList,
            // eslint-disable-next-line vue/no-unused-components
            RoomCardVariant4,
            EventPackageOfferHotelsEmptyState
        },
        testIdOptions: {
            keyPrefix: 'event'
        },
        props: {
            event: {
                type: Object,
                required: true
            },
            busEventParent: {
                type: Object,
                default: null
                // required: true
            },
            selectedHotel: {
                type: Object,
                default: null
            }
        },
        computed: {
            ...mapState( {
                hotels: state => state.packagesOffersV2.hotels.list,
            } ),
            ...mapGetters( {
                loading: `packagesOffersV2/hotels/getLoading`,
            } ),
            eventPackageCardConfig( ) {
                return {
                    card: RoomCardVariant4,
                    cardClasses: 'u-width-100 u-background-transparent',
                    propsToBind: ( item ) => {
                        return {
                            item,
                            showDistance: true,
                            showChangeHotelCardButton: false,
                            showPeriod: true,
                            showHotelOnly: false,
                            busEventParent: this.busEventParent,
                            numberOfItemsToDisplay: this.checkPreBundleFlow ? 3 : 2
                        };
                    }
                };
            },
            checkPreBundleFlow( ) {
                return offerConstants.TYPES.PRE_BUNDLE_PACKAGE === this.hotels[0]?.rooms[0]?.rates[0]?.packageOffer?.ticketType;
            },
            formattedHotels() {
                return this.hotels.map( hotel => {
                    return {
                        hotelImage: hotel.image.original.url,
                        rating: hotel.awards.length ? hotel.awards[0].rating : 0,
                        currency: hotel.selectedRoom.selectedRate.currency,
                        price: hotel.selectedRoom.selectedRate.packageOffer.basePrice,
                        estimatedDistance: this.$options.filters.distanceToVenue( hotel.distance, getDistanceUnitOfMeasureForCountry ( this.event.venueCountry ) ),
                        hotelName: hotel.name,
                        packageOfferId: hotel.selectedRoom.selectedRate.packageOffer.id,
                        stayPeriod: this.$options.filters.reservationDatePeriod( hotel.selectedRoom.accommodation.startDateTime, hotel.selectedRoom.accommodation.endDateTime ),
                        basePrice: hotel.selectedRoom.selectedRate.packageOffer.basePrice,
                        isSelected: this.selectedHotel === hotel,
                        _originalData: hotel,
                        packageOfferInventory: hotel.selectedRoom.selectedRate.packageOffer.inventory,
                        packageHighlights: hotel.packageHighlights,
                        adultsCount: hotel.selectedRoom.accommodation.guests.adultsCount
                    };
                } );
            }
        }
    };
</script>

<style lang="scss">
@import "@scssVariables";
@import "@scssMixins";

.gst-event-package-offers-list {
    padding: theme-spacing( 0 ) theme-spacing( 2 ) !important;

    .gst-event-package-offers-list__list-item {
        margin-top: theme-spacing( 2 );
    }

    .gst-event-package-offers-list__list-item::before,
    .gst-event-package-offers-list__list-itemitem::after {
        content: none !important;
    }

    .gst-load-more-container {
        display: none;
    }

    .v-list {
        padding-bottom: theme-spacing( 0 );
    }

    @include mobile-only {
        > div {
            padding-bottom: theme-spacing( 6 ) !important;
        }
    }
}
</style>
