<template>
    <div class="gst-event-tickets-empty-state">
        <v-img :src="TicketsEmptyStateIcon" />
        <p>{{ $t('title') }}</p>
        <BaseButton
            outlined
            :placeholder="$t('button')"
            class="gst-event-tickets-empty-state__see-available-packages-btn"
            color="primary"
            @click="$emit('see-available-packages')" />
    </div>
</template>

<script>
    import BaseButton from '@core/shared/components/buttons/BaseButton';
    import TicketsEmptyStateIcon from '@tenants/ticketmaster/app/assets/icons/tickets-empty-state.png';

    export default {
        name: 'EventTicketsEmptyState',
        components: {
            BaseButton
        },
        data: () => ( {
            TicketsEmptyStateIcon
        } ),
        i18nOptions: {
            namespaces: 'main',
            keyPrefix: 'views.eventV2.theEvent._components.eventTicketsEmptyState'
        }
    };
</script>

<style lang="scss" scoped>
@import '@scssVariables';
@import '@scssMixins';

.gst-event-tickets-empty-state {
    display: flex;
    padding-top: theme-spacing( 9 );
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .v-image {
        min-height: 158px;
    }

    p {
        width: 50%;
        line-height: line-height( 'xxxl' );
        padding-top: theme-spacing( 7 );
        color: theme-color( 'quaternary' );
        font-size: font-size( 'l' );
        font-weight: font-weight( 'large' );
        text-align: center;
    }

    .gst-event-tickets-empty-state__see-available-packages-btn {
        line-height: line-height( 'xl' );
        margin-top: theme-spacing( 3 );
        color: theme-color( 'primary' );
        font-size: font-size( 's' );
        font-weight: font-weight( 'large' );
        letter-spacing: normal;
        text-transform: unset;
    }
}
</style>