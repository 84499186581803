<template>
    <div ref="modalBody" class="gst-hotel-details d-flex flex-column rounded">
        <HotelDetailsModalHeader class="flex-grow-0" :item="item" @close="$emit('close')">
            <template v-if="$vuetify.breakpoint.mdAndUp" v-slot:addressBar>
                <HotelDetailsAddressBar :item="item" :event="event" @show-map="onShowMapDo" />
            </template>
        </HotelDetailsModalHeader>
        <HotelDetailsModalGallery ref="modalImageGallery" :images="item.images" />
        <HotelDetailsAddressBar v-if="!$vuetify.breakpoint.mdAndUp" :item="item" :event="event" @show-map="onShowMapDo" />
        <HotelDetailsModalBody
            ref="modalNavigationBody"
            :item="item"
            :is-selected="isSelected"
            :loading="false"
            :event="event"
            :has-value="hasValue"
            @select="onSelectHotelDo"
            @close="$emit('close')" />
    </div>
</template>

<script>
    import debounce from 'lodash/debounce';
    import CloseModalOnRouteChangeMixin from '@core/mixins/modals/CloseModalOnRouteChange';
    import CloseModalOnBreakpointChangeMixin from '@core/mixins/modals/CloseModalOnBreakpointChange';
    import HotelDetailsModalHeader from './HotelDetailsV2/HotelDetailsHeader';
    import HotelDetailsAddressBar from './HotelDetailsV2/HotelDetailsAddressBar';
    import HotelDetailsModalGallery from './HotelDetailsV2/HotelDetailsGallery';
    import HotelDetailsModalBody from './HotelDetailsBundleV2/HotelDetailsBody';

    export default {
        name: 'HotelDetailsBundle',
        components: {
            HotelDetailsModalHeader,
            HotelDetailsModalGallery,
            HotelDetailsModalBody,
            HotelDetailsAddressBar
        },
        mixins: [ CloseModalOnRouteChangeMixin, CloseModalOnBreakpointChangeMixin ],
        props: {
            item: {
                type: Object,
                default: null
            },
            isSelected: {
                type: Boolean,
                default: false
            },
            event: {
                type: Object,
                default: null
            },
            busEventParent: {
                type: Object,
                default: null
            },
            hasValue: {
                type: Boolean,
                default: false
            }
        },
        methods: {
            onSelectHotelDo( ) {
                this.busEventParent.$emit( 'select-hotel', this.item );
                setTimeout( () => this.$emit( 'close' ), 500 );
            },
            scrollToMap: debounce( function( ) {
                const modalBodyRef =  this.$refs['modalBody'];
                const imageGalleryHeight = this.$refs['modalImageGallery'].$el.clientHeight;

                const overviewTabMapRef = this.$refs.modalNavigationBody.$refs.overviewTab.$refs.map;
                const scrollTopOffset = overviewTabMapRef.offsetTop + imageGalleryHeight;

                modalBodyRef.scrollTo( {
                    top: scrollTopOffset,
                    behavior: 'smooth'
                } );
            }, 250 ),
            onShowMapDo( ) {
                this.scrollToMap( );
            }
        }
    };
</script>

<style lang="scss">
@import "@scssVariables";
@import "@scssMixins";

.gst-hotel-details {
    position: relative;
    padding-bottom: 16px;
    background-color: theme-color( 'white' ) !important;
    color: theme-color( 'quaternary' );
    overflow-x: hidden;
    overflow-y: scroll;
}
</style>
