<template>
    <div v-if="isHotelsTabActive" class="d-flex flex-column flex-md-row u-width-100 u-height-100" :style="{ 'flex': 1, 'min-height': 0 }">
        <div v-if="$vuetify.breakpoint.mdAndUp" class="col col-md-8 col-lg-9 px-0 py-0 u-height-100 ml-auto mr-auto d-flex justify-center">
            <HotelDetailsBundleV2 :style="{'max-width' : '970px'}" class="u-height-100 u-overflow-scroll-y" :item="hotelForShowingDetails" :event="event" />
        </div>
        <div class="col col-md-4 col-lg-3 pa-0 u-height-100" :style="{ 'min-height': 0 }">
            <EventSidebar
                :disabled-button="!selectedHotel"
                :footer-title="$t( '_components.eventSidebar.footer.title' )"
                :submit-button-placeholder="$t( '_components.eventSidebar.buttons.tickets' )"
                :bundle-products="bundleProducts"
                :event="event"
                @submit="confirmSelectedHotel">
                <EventPackageOfferHotelsList
                    :event="event"
                    :bus-event-parent="busEvent"
                    :selected-hotel="selectedHotel"
                    @select="handleHotelSelection"
                    @more-info="openHotelDetails" />
            </EventSidebar>
        </div>
    </div>
    <div v-else class=" gst-event-ticket-detail-content d-flex flex-column flex-md-row u-width-100" :style="{ 'flex': 1, 'min-height': 0 }">
        <div v-if="$vuetify.breakpoint.mdAndUp" class="col col-md-8 col-lg-9 col pa-0 ml-auto mr-auto">
            <div :style="{'max-width' : '970px', 'margin': '0 auto'}" class="u-width-100 u-height-100">
                <EventTicketImage v-if="$vuetify.breakpoint.mdAndUp"
                    :venue-details="venueDetails"
                    :venue-seat-map-url="null"
                    :image-url-no-seats="event.imageUrl" />
                <EventVenueImage v-else-if="selectedHotel" :venue-details="venueDetails" />
            </div>
        </div>
        <div class="col col-md-4 col-lg-3 pa-0" :style="{ 'min-height': 0 }">
            <EventSidebar
                class="gst-event-ticket-detail-content__sidebar"
                :disabled-button="!canSubmit"
                :display-footer="isFooterDisplayed"
                :footer-title="$t( `_components.eventSidebar.footer.title` )"
                :submit-button-placeholder="$t( '_components.eventSidebar.buttons.checkout' )"
                :bundle-products="bundleProducts"
                :event="event"
                @submit="onSubmitDo">
                <EventPackageOfferTicketSectionsList
                    :event="event"
                    :venue-details="venueDetails"
                    :selected-hotel="selectedHotel"
                    :selected-ticket="selectedTicket"
                    :bundle-products="bundleProducts"
                    @see-available-packages="onChangeHotelClickDo"
                    @change-hotel="onChangeHotelClickDo"
                    @highlight-ticket="handleTicketHighlight"
                    @select="handleTicketSelection" />
            </EventSidebar>
        </div>
    </div>
</template>

<script>
    import Vue from 'vue';
    import { mapActions, mapState, mapGetters } from 'vuex';
    import HotelDetailsBundleV2 from '@tenant/app/modules/hotel/components/containers/HotelDetailsBundleV2.vue';
    import { create as CreateBundleProductsModel } from '@tenant/app/modelsV2/BundleProductsModel';
    import { create as CreateBundleProductsHotelReservationModel } from '@tenant/app/modelsV2/BundleProductsHotelReservationModel';
    import { create as CreateBundleProductsEventTicketModel } from '@tenant/app/modelsV2/BundleProductsEventTicketModel';
    import EventFlowMixin from '@tenant/app/mixins/EventFlowMixin';
    import EventSidebar from './_components/EventSidebar.vue';
    import EventTicketImage from './_components/EventTicketImage.vue';
    import EventVenueImage from './_components/EventVenueImage.vue';
    import EventPackageOfferHotelsList from './EventPackageOffers/EventPackageOfferHotelsList.vue';
    import EventPackageOfferTicketSectionsList from './EventPackageOffers/EventPackageOfferTicketSectionsList.vue';

    const EVENT_TABS = {
        PACKAGES: 'packages',
        TICKETS: 'tickets',
    };

    export default {
        name: 'EventContentPackageOffers',
        components: {
            EventSidebar,
            EventTicketImage,
            EventVenueImage,
            EventPackageOfferHotelsList,
            EventPackageOfferTicketSectionsList,
            HotelDetailsBundleV2
        },
        mixins: [ EventFlowMixin ],
        props: {
            activeTab: {
                type: String,
                required: true
            },
            event: {
                type: Object,
                required: true
            },
            venueDetails: {
                type: Object,
                required: true
            },
            hotels: {
                type: Array,
                required: true
            }
        },
        data( ) {
            return {
                busEvent: new Vue( ),
                bundleProducts: CreateBundleProductsModel( ),
                selectedHotel: null,
                selectedTicket: null
            };
        },
        computed: {
            ...mapState( {
                hotelsList: state => state.packagesOffersV2.hotels.list
            } ),
            ...mapGetters( {
                getAvailable                    : `packagesOffersV2/getAvailable`,
                loadingSections                 : `packagesOffersV2/getLoading`,
            } ),
            isHotelsTabActive( ) {
                return this.activeTab === EVENT_TABS.PACKAGES;
            },
            canSubmit( ) {
                return this.selectedHotel && this.selectedTicket;
            },
            isFooterDisplayed() {
                if( this.$vuetify.breakpoint.mdAndUp ) {
                    return true;
                }
                return !!( this.selectedHotel && this.selectedTicket );
            },
            hotelForShowingDetails( ) {
                return this.selectedHotel || this.hotels[ 0 ];
            }
        },
        methods: {
            ...mapActions( {
                oneByEventId                    : `packagesOffersV2/oneByEventId`,
                getHotelsByEventId              : `packagesOffersV2/hotels/getByEventId`,
            } ),
            isHotelSelected( item ) {
                return item === this.selectedHotel;
            },
            openHotelDetails( item ) {
                const modal = import( '@tenant/app/modules/hotel/components/containers/HotelDetailsBundleV2.vue' );

                const mobileProps = {
                    'content-class': 'v-dialog__height-98 gst-v-dialog',
                    scrollable: true,
                    fullscreen: false,
                    'hide-overlay': false,
                    transition: 'dialog-bottom-transition'
                };

                const props = {
                    'no-click-animation': true,
                    scrollable: true,
                    fullscreen: false,
                    persistent: false,
                    'hide-overlay': false,
                    'max-width': 920,
                    'content-class': 'gst-v-dialog'
                };

                const componentProps = {
                    item: item,
                    event: this.event,
                    busEventParent: this.busEvent,
                    isSelected: this.isHotelSelected( item )
                };

                if( this.$vuetify.breakpoint.mdAndUp ) {
                    this.$modal.show( modal, componentProps, props );
                } else {
                    this.$modal.showBottom( modal, componentProps, mobileProps );
                }
            },
            handleHotelSelection( hotel ) {
                if ( this.selectedHotel !== hotel ) {
                    this.selectedTicket = null;
                    this.selectedHotel = hotel;
                    this.bundleProducts = CreateBundleProductsModel(
                        null,
                        CreateBundleProductsHotelReservationModel( hotel )
                    );
                }
            },
            handleTicketSelection( ticket ) {
                this.selectedTicket = ticket;

                // Highlight position in map
                if ( ticket ) {
                    this.venueDetails.mapUrl = ticket.snapshotImageUrl;
                    this.venueDetails.mapWithlabelsUrl = ticket.snapshotImageUrl;
                }

                const quantity = this.bundleProducts.getQuantity ( ).guests.adultsCount || 2;
                this.bundleProducts.eventTicket = CreateBundleProductsEventTicketModel(
                    this.event,
                    null,
                    ticket,
                    quantity,
                    0,
                    0
                );
            },
            handleTicketHighlight( ticket ) {
                if ( ticket ) {
                    this.venueDetails.mapUrl = ticket.venueSeatMapUrl;
                    this.venueDetails.mapWithlabelsUrl = ticket.venueSeatMapUrl;
                } else {
                    // If ticket is selected, should not be removed on mouse leave.
                    if ( !this.selectedTicket ) {
                        this.venueDetails.mapUrl = this.venueDetails.defaultMapUrl;
                        this.venueDetails.mapWithlabelsUrl = this.venueDetails.defaultMapUrl;
                    }
                }
            },
            changeTabToPackages( ) {
                this.$emit( 'changeTab', { tab: EVENT_TABS.PACKAGES } );
            },
            changeTabToTickets( ) {
                this.$emit( 'changeTab', { tab: EVENT_TABS.TICKETS } );
            },
            confirmSelectedHotel( ) {
                this.changeTabToTickets( );
            },
            onSubmitDo() {
                if ( !this.canSubmit ) {
                    return;
                }
                const { bundleProducts, venueDetails, event } = this;
                const { hotelReservation, eventTicket } = bundleProducts;
                const { section } = eventTicket;
                const hotel = hotelReservation.hotel;
                const room = hotel.selectedRoom;
                const packageOffer = room.selectedRate.packageOffer;
                const quantity = this.bundleProducts.getQuantity( );

                let payload = {
                    ticket: {
                        eventId:                event.id,
                        offers:                 [ {
                            ticketTypeId:       packageOffer.ticketTypeId,
                            quantity:           quantity.tickets
                        } ],
                        section:                section.section,
                        venueSeatMapUrl:        section.venueSeatMapUrl,
                        venueMapUrl:            venueDetails.mapUrl
                    },
                    hotelReservation: {
                        hotel,
                        room,
                        accommodation: {
                            startDateTime:  hotelReservation.startDateTime,
                            endDateTime:    hotelReservation.endDateTime,
                            roomsCount:     quantity.roomsCount,
                            guests:         quantity.guests,
                        },
                    },
                    offer: {
                        id: packageOffer.id
                    }
                };

                this.$emit( 'checkout', payload );
            },
            onChangeHotelClickDo() {
                this.$emit( 'changeTab', { tab: EVENT_TABS.PACKAGES, shouldScrollToSelectedHotel: !!this.selectedHotel } );
            },
            selectPackageOfferId( packageId ) {
                const preselectedHotelFromEventPackageOffer = this.hotelsList.find( item => item.selectedRoom.selectedRate.packageOffer.id === packageId );

                if ( preselectedHotelFromEventPackageOffer ) {
                    this.selectedHotel = preselectedHotelFromEventPackageOffer;
                    this.bundleProducts = CreateBundleProductsModel(
                        null,
                        CreateBundleProductsHotelReservationModel( preselectedHotelFromEventPackageOffer )
                    );
                    this.changeTabToTickets( );
                    return true;
                }
                return false;
            },
            selectPackageFromRoute( ) {
                const preselectedHotelRoom = this.$route.params.selectedHotelReservationRoom;
                const preselectedHotelPackageOfferId = preselectedHotelRoom && this.$route.params.selectedHotelReservationRoom.packageOfferId;

                this.selectPackageOfferId ( preselectedHotelPackageOfferId );
            },
            selectExperienceFromRoute( ) {
                const urlOfferId = +this.$route.query.selectOfferId;

                if ( !urlOfferId ) {
                    return;
                }
                this.selectPackageOfferId( urlOfferId );
            }
        },
        mounted() {
            if ( this.isExperiencesFlow ) {
                this.selectExperienceFromRoute( );
            } else {
                this.selectPackageFromRoute( );
            }

            this.busEvent.$on( 'more-info', this.openHotelDetails );
            this.busEvent.$on( 'select-hotel', this.handleHotelSelection );
        },
        destroyed( ) {
            this.busEvent.$off( 'more-info' );
            this.busEvent.$off( 'select-hotel' );
        }
    };
</script>

<style lang="scss">
@import '@scssVariables';
@import '@scssMixins';

@include desktop-only {
    .gst-event-ticket-detail-content {
        background-color: theme-color( 'quinary' );
    }

    .gst-event-ticket-detail-content__sidebar {
        background-color: white;
    }
}

@include mobile-only {
    .gst-event-ticket-detail-content__sidebar {
        height: calc( var( --vh, 1vh ) * 100 - 90px );
    }
}
</style>
