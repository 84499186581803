<template>
    <div class="gst-hotel-details-body">
        <HotelDetailsOverviewTab
            ref="overviewTab"
            :item="item"
            :event="event"
            :is-selected="isSelected"
            :is-package-flow="true"
            @select="$emit('select')" />
    </div>
</template>

<script>

    import HotelDetailsOverviewTab from '../HotelDetailsV2/HotelDetailsBody/HotelDetailsOverviewTab';

    export default {
        name: 'HotelDetailsBody',
        components: {
            HotelDetailsOverviewTab
        },
        props: {
            item: {
                type: Object,
                default: null
            },
            isSelected: {
                type: Boolean,
                default: false,
            },
            event: {
                type: Object,
                default: null
            },
            loading: {
                type: Boolean,
                default: false
            },
            hasValue: {
                type: Boolean,
                default: false
            },
        }
    };
</script>
