import { render, staticRenderFns } from "./EventPackageOfferTicketSectionsEmptyState.vue?vue&type=template&id=b0c5fef2&scoped=true&"
import script from "./EventPackageOfferTicketSectionsEmptyState.vue?vue&type=script&lang=js&"
export * from "./EventPackageOfferTicketSectionsEmptyState.vue?vue&type=script&lang=js&"
import style0 from "./EventPackageOfferTicketSectionsEmptyState.vue?vue&type=style&index=0&id=b0c5fef2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b0c5fef2",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VImg})
