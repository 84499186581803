<template>
    <div
        :class="[item.isSelected ? 'selected' : '', isSelectedHotelOnTicketsTab ? 'ticketsListHeaderItem' : '']"
        class="gst-room-card-variant-4"
        tabindex="0"
        @click="onItemClickDo(item)"
        @keypress.enter="onItemClickDo(item)">
        <div v-if="displayRemainingInventory" class="gst-room-card-variant-4__package-offer-inventory">
            {{ $t( '_common:terms.packageOfferInventory', { count: item.packageOfferInventory } ) }}
        </div>
        <div
            v-if="!$vuetify.breakpoint.mdAndUp && item.hotelImage"
            class="gst-room-card-variant-4__image mx-n2">
            <BaseImage :src="item.hotelImage" />
        </div>
        <div class="gst-room-card-variant-4__info d-flex align-start justify-space-between flex-column">
            <GuestsBadge v-if="showGuestBadge" class="mb-2" :number-of-guests="displayAdultsCounts" />
            <div class="d-flex align-start justify-space-between u-width-100">
                <div class="u-width-100">
                    <div
                        class="gst-room-card-variant-4__name d-flex"
                        :class="{
                            'align-center': ratingPosition === 'right',
                            'flex-column-reverse': ratingPosition === 'top'
                        }">
                        <div class="gst-room-card-variant-4__hotel-name mb-1 mr-2">
                            {{ item.hotelName }}
                        </div>
                        <StarRating :value="item.rating" class="star-rating mb-1 d-flex align-self-start" />
                    </div>
                    <p v-if="displayDistance" class="gst-room-card-variant-4__walk">
                        {{ item.estimatedDistance }}
                    </p>
                </div>
            </div>
        </div>
        <Highlights
            :data="highlightsItemsToDisplay" 
            :hide-icons="hideIcons" 
            :number-of-items-to-display="numberOfItemsToDisplay" 
            :show-all-external="showAllExternal"
            is-external-toggle
            @expanded="setCurrentItem" />
        <template v-if="item.price">
            <div class="gst-room-card-variant-4__price__per__person d-flex align-center">
                <span>{{ personPackagePrice | currencyFilter( item.currency ) }}</span>
                <span class="ml-1"> {{ $t('_common:terms.perPerson') }}</span>
            </div>
            <div class="gst-room-card-variant-4__price__per__package d-flex align-center">
                <span>{{ item.price | currencyFilter( item.currency ) }}</span>
                <span class="ml-1"> {{ $t('_common:terms.perPackage') }}</span>
            </div>
        </template>
        <div
            v-if="!$vuetify.breakpoint.mdAndUp && !isSelectedHotelOnTicketsTab"
            class="gst-room-card-variant-4__action-buttons d-flex items-center justify-space-between mt-3">
            <BaseButton
                outlined
                color="primary"
                :placeholder="$t('_common:buttons.moreInfo')"
                class="gst-btn more-info-btn"
                @click="onMoreInfoBtnClickDo" />
            <BaseButton
                color="primary"
                :placeholder="$t('_common:buttons.selectPackage')"
                class="gst-btn select-package-btn"
                :disabled="item.isSelected"
                @click="onSelectPackageDo" />
        </div>
        <BaseButton
            v-if="displayChangeHotelCardButton"
            placeholder="Change"
            class="gst-event-tickets-list__change-hotel-btn"
            invert
            @click="$emit('change-hotel')" />
    </div>
</template>

<script>
    import { mapState, mapActions, mapGetters } from 'vuex';
    import { divide as divideNumbers } from '@core/utils/numberUtils.js';
    import BaseButton from '@core/shared/components/buttons/BaseButton';
    import StarRating from '@core/shared/components/misc/StarRating';
    import GuestsBadge from '@tenants/ticketmaster/app/components/badges/GuestsBadge.vue';
    import HighLightsItemsMixin from '@tenants/ticketmaster/app/mixins/HighLightsItemsMixin';
    import Highlights from '../misc/Highlights/Highlights.vue';
    import BaseImage from '../BaseImage.vue';

    const NUMBER_TO_DISPLAY_PACKAGE_OFFER_INVENTORY = 10;

    export default {
        name: 'RoomCardVariant4',
        components: {
            BaseButton,
            StarRating,
            BaseImage,
            Highlights,
            GuestsBadge
        },
        mixins: [
            HighLightsItemsMixin
        ],
        props: {
            item: {
                type: Object,
                required: true
            },
            isSelectedHotelOnTicketsTab: {
                type: Boolean,
                default: false
            },
            showPeriod: {
                type: Boolean,
                default: false
            },
            showHotelOnly: {
                type: Boolean,
                default: true
            },
            showDistance: {
                type: Boolean,
                default: false
            },
            showChangeHotelCardButton: {
                type: Boolean,
                default: false
            },
            hideIcons: {
                type: Boolean,
                default: false
            },
            numberOfItemsToDisplay: {
                type: Number,
                default: 2
            },
            ratingPosition: {
                type: String,
                default: 'right',
                validator: function ( value ) {
                    return [ 'right', 'top' ].includes( value );
                }
            },
            busEventParent: {
                type: Object,
                default: null
            }
        },
        data: () => ( {
            NUMBER_TO_DISPLAY_PACKAGE_OFFER_INVENTORY: 10,
        } ),
        computed: {
            ...mapState( {
                expandedItem: state => state.packagesOffersV2.hotels.expandedItem
            } ),
            ...mapGetters( {
                showGuestBadge: 'packagesOffersV2/hotels/hasDifferentGuestNumber'
            } ),
            displayDistance() {
                if ( this.$vuetify.breakpoint.mdAndUp ) {
                    return true;
                }

                return this.showDistance;
            },
            displayChangeHotelCardButton() {
                return this.showChangeHotelCardButton;
            },
            displayPeriodHighlight() {
                return this.showPeriod && this.item.stayPeriod;
            },
            displayAdultsCounts( ) {
                return this.item.adultsCount;
            },
            personPackagePrice( ) {
                return divideNumbers( this.item.price, this.displayAdultsCounts );
            },
            displayHotelHighlightOnly() {
                return this.showHotelOnly;
            },
            displayRemainingInventory() {
                return this.item.packageOfferInventory && this.item.packageOfferInventory < NUMBER_TO_DISPLAY_PACKAGE_OFFER_INVENTORY;
            },
            truncateText( ) {
                return !this.$vuetify.breakpoint.mdAndUp && this.isSelectedHotelOnTicketsTab;
            },
            showAllExternal( ) {
                return this.item._originalData?.id === this.expandedItem;
            },
        },
        methods: {
            ...mapActions( {
                setExpandedItem : `packagesOffersV2/hotels/updateExpandedItem`,
            } ),
            onItemClickDo( item ) {
                this.$emit( 'click', item._originalData );
            },
            onSelectPackageDo( event ) {
                event.stopPropagation( );
                this.$emit( 'click', this.item._originalData );
            },
            onMoreInfoBtnClickDo( event ) {
                event.stopPropagation( );
                if ( this.busEventParent ) {
                    this.busEventParent.$emit( 'more-info', this.item._originalData );
                }
            },
            setCurrentItem( ) {
                if ( this.expandedItem === this.item._originalData.id ) {
                    this.setExpandedItem( null );
                } else {
                    this.setExpandedItem( this.item._originalData.id );
                }
            }
        }
    };
</script>

<style lang="scss">
@import '@scssVariables';
@import '@scssMixins';

.gst-room-card-variant-4 {
    position: relative;
    padding: theme-spacing( 4 ) theme-spacing( 5 ) !important;
    border: 1px solid #E3E3E3;
    border-radius: border-radius( 'xxs' );

    .gst-room-card-variant-4__package-offer-inventory {
        position: absolute;
        top: -1px;
        right: -1px;
        line-height: line-height( 'xxs' );
        padding: 3px theme-spacing( 1 ) 2px theme-spacing( 1 );
        background: theme-color( 'bonus-points-chip-bg-color' );
        color: theme-color( 'white' );
        font-size: font-size( 'xxxxs' );
        font-weight: font-weight( 'large' );
        text-transform: uppercase;
        border-radius: border-radius( 'none' ) border-radius( 'xs' );
    }

    .gst-room-card-variant-4__name {
        line-height: line-height( 'xxxxl' );
        color: theme-color( 'quaternary' );
        font-size: font-size( 'xxxl' );
        font-weight: font-weight( 'large' );
    }

    .gst-room-card-variant-4__walk {
        line-height: line-height( 'm' );
        color: theme-color( 'tertiary' );
        font-size: font-size( 'xxs' );
    }

    .gst-room-card-variant-4__price__per__person {
        margin-top: theme-spacing( 4 );

        span:nth-child(1) {
            line-height: line-height( 'xxxxxl' );
            color: theme-color( 'anchor' );
            font-size: font-size( 'xxxxl' );
            font-weight: font-weight( 'large' );
        }

        span:nth-child(2) {
            line-height: line-height( 'm' );
            color: theme-color( 'primary' );
            font-size: font-size( 'l' );
            font-weight: font-weight( 'large' );
        }
    }

    .gst-room-card-variant-4__price__per__package {
        margin-top: theme-spacing( 1 );

        span:nth-child(1) {
            line-height: line-height( 'm' );
            color: theme-color( 'anchor' );
            font-size: font-size( 'l' );
        }

        span:nth-child(2) {
            line-height: line-height( 'm' );
            color: theme-color( 'primary' );
            font-size: font-size( 's' );
        }
    }

    .gst-room-card-variant-4__action-buttons {
        button {
            line-height: line-height( 'xl' );
            margin-right: theme-spacing( 1 );
            font-size: font-size( 's' );
            font-weight: font-weight( 'large' );
            letter-spacing: normal;
            flex: 1;
            text-transform: capitalize;
        }

        button:nth-child(2) {
            margin-right: theme-spacing( 0 );
            margin-left: theme-spacing( 1 );
        }
    }

    .gst-event-tickets-list__change-hotel-btn {
        position: absolute;
        top: theme-spacing( 2 );
        right: theme-spacing( 2 );
        line-height: line-height( 'm' );
        font-size: font-size( 'xxs' );
        font-weight: font-weight( 'large' );
        text-transform: capitalize;
    }
}

.gst-room-card-variant-4:not(.ticketsListHeaderItem):hover {
    background: #EBF5FF !important;
    border-color: theme-color( 'transparent' );
}

.gst-room-card-variant-4.selected::after {
    position: absolute;
    top: -1px;
    left: -1px;
    height: calc( 100% + 2px );
    width: 4px;
    background-color: theme-color( 'primary' );
    border-radius: border-radius( 'xxs' ) 0 0 border-radius( 'xxs' );
    content: '';
}

.gst-room-card-variant-4.selected {
    background: #EBF5FF !important;
    border-color: transparent;
}

@include mobile-only {
    .gst-room-card-variant-4 {
        padding: theme-spacing( 2 ) theme-spacing( 4 ) theme-spacing( 4 ) !important;
        border-radius: border-radius( 'xs' );

        .gst-room-card-variant-4__package-offer-inventory {
            top: theme-spacing( 2 );
            right: theme-spacing( 2 );
            z-index: 1;
        }

        .gst-room-card-variant-4__image {
            .v-image {
                height: 170px;
                margin-bottom: theme-spacing( 3 );
                border-radius: border-radius( 'xs' );
            }
        }

        .gst-room-card-variant-4__name {
            position: relative;
            bottom: 4px;
            line-height: line-height( 'xxxl' );
            font-size: font-size( 'l' );
            align-items: flex-start !important;

            .star-rating {
                min-width: fit-content !important;
            }
        }

        .gst-room-card-variant-4__walk {
            line-height: line-height( 'm' );
            font-size: font-size( 'xxs' );
        }

        .gst-room-card-variant-4__price {
            display: flex;
            align-items: center;

            span:nth-child(1) {
                line-height: line-height( 'xxxxl' );
                font-size: font-size( 'xxxl' );
            }

            span:nth-child(2) {
                line-height: line-height( 'm' );
                font-size: font-size( 'xxs' );
                margin-left: theme-spacing( 1 );
            }
        }

        .gst-event-tickets-list__change-hotel-btn {
            position: absolute;
            top: 50%;
            right: 0;
            width: 77px;
            transform: translateY( -50% );
        }
    }

    .gst-room-card-variant-4.selected::after {
        position: absolute;
        top: -1px;
        left: -1px;
        height: 4px;
        width: calc( 100% + 2px );
        background-color: theme-color( 'primary' );
        border-radius: border-radius( 'xs' ) border-radius( 'xs' ) 0 0;
        content: '';
    }

    .gst-room-card-variant-4:not(.ticketsListHeaderItem):not(.selected):hover {
        background: theme-color( 'transparent' ) !important;
        border-color: #E3E3E3 !important;
    }

    .gst-room-card-variant-4.selected {
        background: #EBF5FF !important;
        border-color: transparent;
    }

    .gst-room-card-variant-4.ticketsListHeaderItem::after {
        position: absolute;
        top: -1px;
        left: -1px;
        height: calc( 100% + 2px );
        width: 4px;
        background-color: theme-color( 'primary' );
        border-radius: border-radius( 'xs' ) 0 0 border-radius( 'xs' );
        content: '';
    }

    .gst-room-card-variant-4.ticketsListHeaderItem {
        padding: theme-spacing( 3 ) theme-spacing( 4 ) !important;
        padding-right: 77px !important;
        padding-left: theme-spacing( 5 ) !important;

        .gst-room-card-variant-4__name {
            line-height: line-height( 'l' );
            font-size: font-size( 's' );
        }

        .gst-room-card-variant-4__hotel-name {
            width: 100%;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }
    }
}
</style>
