<template>
    <div
        :class="[ticket.isSelected ? 'selected' : '']"
        class="gst-ticket-card-variant-4 d-flex align-center justify-space-between"
        tabindex="0"
        @click="$emit('click', ticket)"
        @keypress.enter="$emit('click', ticket)">
        <div class="d-flex flex-column">
            <span class="gst-ticket-card-variant-4__name">{{ ticket.name }}</span>
            <span class="gst-ticket-card-variant-4__extra">{{ $t( '_common:terms.standardTicket_plural', { count: quantity } ) }}</span>
        </div>
        <CheckIcon v-if="ticket.isSelected" />
        <span v-else class="gst-ticket-card-variant-4__price">
            + {{ ticketPrice }}
        </span>
    </div>
</template>

<script>
    import CheckIcon from '@tenants/ticketmaster/app/assets/icons/check_round_green.svg';

    export default {
        name: 'TicketCardVariant4',
        components: {
            CheckIcon
        },
        props: {
            ticket: {
                type: Object,
                required: true
            },
            quantity: {
                type: Number,
                default: 2
            }
        },
        computed: {
            ticketPrice: function() {
                return this.$options.filters.currencyFilter( this.ticket.price || 0, this.ticket.currency );
            }
        }
    };
</script>

<style lang="scss">
@import '@scssVariables';
@import '@scssMixins';

.gst-ticket-card-variant-4 {
    padding: theme-spacing( 4 ) theme-spacing( 4 );
    border: 1px solid #E3E3E3;
    border-radius: border-radius( 'xxs' );
}

.gst-ticket-card-variant-4:hover {
    background: #EBF5FF !important;
    border-color: transparent;
}

.gst-ticket-card-variant-4.selected {
    background: #EBF5FF !important;
    border-color: transparent;
}

.gst-ticket-card-variant-4__name {
    line-height: line-height( 'xxxl' );
    color: theme-color( 'quaternary' );
    font-size: font-size( 'l' );
    font-weight: font-weight( 'large' );
}

.gst-ticket-card-variant-4__extra {
    line-height: line-height( 'xl' );
    color: theme-color( 'tertiary' );
    font-size: font-size( 's' );
}

.gst-ticket-card-variant-4__price {
    line-height: line-height( 'xl' );
    color: theme-color( 'quaternary' );
    font-size: font-size( 's' );
}
</style>