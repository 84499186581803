<template>
    <div class="gst-event-tickets-list pt-2" :style="{ 'flex': 1, 'min-height': 0 }">
        <DataLoading v-if="loading" class="u-width-100 u-height-100 d-flex align-center justify-center gst-event-tickets-list__loader" />
        <template v-else>
            <div v-if="!tickets.length || !selectedHotel">
                <EventPackageOfferTicketSectionsEmptyState
                    @see-available-packages="$emit('see-available-packages')" />
            </div>
            <div v-else class="d-flex flex-column u-height-100">
                <RoomCardVariant4
                    class="mb-2"
                    :item="hotel"
                    v-bind="selectedHotelProps"
                    rating-position="top"
                    :show-period="false"
                    :show-distance="$vuetify.breakpoint.mdAndUp"
                    hide-icons
                    :bus-event-parent="busEventParent"
                    @change-hotel="$emit('change-hotel')" />
                <EventVenueImage v-if="$vuetify.breakpoint.smAndDown" :image-url-no-seats="event.imageUrl" :loading="loadingTickets" :venue-details="venueDetails" />
                <div class="gst-event-tickets-list__choose-section d-flex align-center justify-space-between mt-1">
                    <span>{{ $t('_common:terms.chooseSection') }}</span>
                    <span>{{ $t('_common:terms.seatsPlacedTogether') }}</span>
                </div>
                <div :style="{ 'flex': 1, 'min-height': 0 }">
                    <InfiniteCardList
                        :data="ticketsList"
                        :config="eventTicketCardConfig"
                        :loading="loading"
                        :has-load-button="false"
                        :prefill="true"
                        item-classes="gst-event-tickets-list__list-item"
                        list-classes="pt-0"
                        class="gst-event-tickets-list__tickets-list u-height-100 u-width-100"
                        :bus-event-parent="busEventParent"
                        @mouse-enter-item="$emit('highlight-ticket', $event )"
                        @mouse-leave-item="$emit('highlight-ticket', null )"
                        @click-item="$emit('select', $event._originalData)"
                        @load-more="$emit('load-more')" />
                </div>
            </div>
        </template>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import { getDistanceUnitOfMeasureForCountry } from '@core/utils/measureUtils';
    import DataLoading from '@core/shared/components/loading/DataLoading';
    import InfiniteCardList from '@core/shared/components/lists/InfiniteCardList';
    import TicketCardVariant4 from '@tenant/app/components/cards/TicketCardVariant4';
    import RoomCardVariant4 from '@tenants/ticketmaster/app/components/cards/RoomCardVariant4.vue';
    import EventVenueImage from '@tenants/ticketmaster/app/views/eventV2/TheEvent/_components/EventVenueImage.vue';
    import EventPackageOfferTicketSectionsEmptyState from './EventPackageOfferTicketSectionsEmptyState.vue';

    export default {
        name: 'EventPackageOfferTicketSectionsList',
        components: {
            DataLoading,
            InfiniteCardList,
            // eslint-disable-next-line vue/no-unused-components
            TicketCardVariant4,
            EventPackageOfferTicketSectionsEmptyState,
            RoomCardVariant4,
            EventVenueImage
        },
        props: {
            event: {
                type: Object,
                required: true
            },
            venueDetails: {
                type: Object,
                default: null
            },
            loadingTickets: {
                type: Boolean,
                default: false,
            },
            ticket: {
                type: Object,
                default: null,
                // required: true
            },
            busEventParent: {
                type: Object,
                default: null
                // required: true
            },
            selectedHotel: {
                type: Object,
                default: null
            },
            selectedTicket: {
                type: Object,
                default: null
            },
            bundleProducts: {
                type: Object,
                required: true
            },
        },
        data: function() {
            return {
                tickets: [],
                loading: false,
                totalTickets: null
            };
        },
        testIdOptions: {
            keyPrefix: 'event'
        },
        computed: {
            ticketsQuantity( ) {
                const quantity = this.bundleProducts.getQuantity( );

                return quantity.tickets || quantity.guests.adultsCount;
            },
            eventTicketCardConfig( ) {
                return {
                    card: TicketCardVariant4,
                    cardClasses: 'u-width-100 u-background-transparent',
                    propsToBind: ( item ) => {
                        return {
                            ticket: {
                                ...item,
                                currency: item.currency || this.selectedHotel.selectedRoom.selectedRate.currency
                            },
                            quantity: this.ticketsQuantity
                        };
                    }
                };
            },
            hotel( ) {
                const { selectedHotel } = this;

                return {
                    hotelName: selectedHotel.name,
                    rating: selectedHotel.awards.length ? selectedHotel.awards[0].rating : 0,
                    packageHighlights: selectedHotel.packageHighlights,
                    estimatedDistance: this.$options.filters.distanceToVenue( selectedHotel.distance, getDistanceUnitOfMeasureForCountry ( this.event.venueCountry ) )
                };
            },
            selectedHotelProps() {
                return {
                    showDistance: true,
                    showChangeHotelCardButton: true,
                    showPeriod: true,
                    showTickets: false,
                    isSelectedHotelOnTicketsTab: true,
                };
            },
            ticketsList() {
                return this.tickets.map( ( ticket ) => {
                    // TODO update ticket normalization is added, change this to ID instead of section
                    const selectedTicketSection = this.selectedTicket && this.selectedTicket.section || null;

                    return {
                        name: `Section ${ticket.section}`,
                        currency: this.selectedHotel.currency,
                        venueSeatMapUrl: ticket.snapshotImageUrl,
                        isSelected: selectedTicketSection === ticket.section,
                        _originalData: ticket
                    };
                } );
            }
        },
        methods: {
            ...mapActions( {
                getTicketSectionsByPackageOfferId: 'packagesOffersV2/getTicketSectionsByPackageOfferId'
            } )
        },
        async mounted() {
            if ( this.selectedHotel ) {
                const packageOfferId = this.selectedHotel.selectedRoom.selectedRate.packageOffer.id;

                this.loading = true;
                const sections = await  this.getTicketSectionsByPackageOfferId( { packageOfferId } );

                if ( sections && sections.data ) {
                    this.tickets = sections.data.sections;
                }

                this.loading = false;
            }
        }
    };
</script>

<style lang="scss">
@import '@scssVariables';
@import '@scssMixins';

.gst-event-tickets-list {
    padding: theme-spacing( 0 ) theme-spacing( 2 );

    .gst-event-tickets-list__choose-section {
        padding: theme-spacing( 2 );
        padding-bottom: theme-spacing( 3 );
        padding-left: theme-spacing( 0 );

        span:nth-child(1) {
            line-height: line-height( 'xxxl' );
            color: theme-color( 'quaternary' );
            font-size: 16px;
            font-weight: font-weight( 'large' );
        }

        span:nth-child(2) {
            line-height: line-height( 'm' );
            color: theme-color( 'tertiary' );
            font-size: font-size( 'xxs' );
        }
    }

    .gst-event-tickets-list__list-item {
        padding: theme-spacing( 0 ) !important;
        margin-bottom: theme-spacing( 2 );
        box-sizing: border-box;
        border-radius: border-radius( 'xs' );
    }

    .gst-event-tickets-list__list-item::before,
    .gst-event-tickets-list__list-item::after {
        content: none !important;
    }

    .gst-event-tickets-list__list-item:last-child {
        margin-bottom: theme-spacing( 0 );
    }

    .gst-load-more-container {
        display: none;
    }

    .v-list {
        padding-bottom: theme-spacing( 0 );
    }
}
</style>
